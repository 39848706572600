<template>
  <div class="page" v-if="detailInfo.operateRecordDTOS">
    <div
      class="operate-item"
      v-for="(record, index) in detailInfo.operateRecordDTOS"
      :key="index"
    >
      <div
        class="status warning"
        :class="{
          danger: ['ROLLBACK', 'CANCEL', 'END'].includes(record.results),
          success: ['PASS'].includes(record.results),
        }"
      >
        {{ record.resultsStr }}
      </div>

      <div class="row">
        家访结果：{{ record.passResult | enumMessage(getHomePassResultEnum) }}
      </div>
      <div class="row">提交人：{{ record.createBy }}</div>
      <div class="row">提交时间：{{ record.createTime }}</div>
      <div class="row">审核意见：{{ record.reason }}</div>
      <div class="divider"></div>
      <div class="footer">
        <div>审核时间：{{ record.approvalTime }}</div>
        <van-button
          type="info"
          size="mini"
          @click="$emit('settle')"
          :disabled="!applyInfo || applyInfo.subject == null"
        >
          查看
        </van-button>
      </div>
    </div>

    <van-empty
      v-if="detailInfo.operateRecordDTOS.length == 0"
      description="暂无审核记录"
    />
  </div>
</template>
<script>
import Vue from "vue";

import { Empty } from "vant";

Vue.use(Empty);

export default {
  props: {
    detailInfo: {
      type: Object,
      required: true,
    },
    applyInfo: {
      type: Object,
    },
  },
  methods: {},
  mounted() {
    this.fetchEnums("getHomePassResultEnum");
  },
};
</script>
<style lang="less" scoped>
.success {
  color: #07c160;
}
.danger {
  color: #ff4848;
}
.warning {
  color: #fa9540;
}
.page {
  padding: 10px 5px;
  position: relative;
  box-sizing: border-box;

  .operate-item {
    background: #fff;
    margin-bottom: 15px;
    position: relative;

    .row {
      padding: 4px 10px;
      line-height: 1.5em;
      word-break: break-all;
    }

    .divider {
      height: 1px;
      background: #f2f2f2;
    }
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
    }

    .status {
      position: absolute;
      right: 30px;
      top: 40px;
    }
  }
}
</style>